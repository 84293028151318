import 'regenerator-runtime/runtime';

import type { ApolloError, InMemoryCache } from '@apollo/client';
import { useNextLinkResolver, useNextLinkWrapper } from '@elseu/sdu-next-utils';
import { ThemeProvider } from '@elseu/sdu-titan';
import { ContentExportProvider } from '@elseu/sdu-titan-content-export-react';
import {
  AlertsProvider,
  AnalyticsProvider,
  AuthComponents,
  BaseLayout,
  BaseLayoutSimple,
  constructSearchUrlResolver,
  ContentRenderingProviderWithPlugins,
  ErrorBoundary,
  ErrorBoundaryPageFallback,
  GlobalSiteStyling,
  InPageSearchProviderWithRouter,
  NavigationProvider,
  renderReadingListButtonFunction,
  sentryDialogOptions,
  SiteConfigProvider,
  TitanCmsProvider,
  TranslationsProvider,
  useApolloCacheControl,
  useSiteConfig,
  withApolloServerSideRender,
  withBot,
  withCookies,
  withLingui,
  withServerContext,
} from '@elseu/sdu-titan-product-site-components';
import { ReadingListsProvider } from '@elseu/sdu-titan-reading-lists-react';
import {
  SearchAutocompleteProvider,
  SearchRootProvider,
  XposiProvider,
} from '@elseu/sdu-titan-search';
import { type Messages } from '@lingui/core';
import { useLingui } from '@lingui/react';
import { AuthenticationProvider, useAuthInitialization } from '@repo/auth';
import * as Sentry from '@sentry/nextjs';
import type { AppProps } from 'next/app';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import { DefaultSeo } from 'next-seo';
import { useCallback, useMemo } from 'react';

import { config } from '@/config';
import { getDocumentUrl } from '@/helpers/getDocumentUrl';
import { getDefaultSeo } from '@/helpers/seo';
import { contentRenderingUrls } from '@/helpers/urls';
import { loadMessages } from '@/translations/utils';
import type { NextPageWithLayout } from '@/types/NextPageWithLayout';

const useLinkWrapper = () => {
  const router = useRouter();
  return useNextLinkWrapper({
    nextVersion: 14,
    pathname: router.pathname,
    linkComponent: NextLink,
  });
};

const useLinkResolver = () => {
  const router = useRouter();
  return useNextLinkResolver({
    push: router.push,
  });
};

function AppProvidersWithAuth({ children }: React.PropsWithChildren) {
  const ApolloCacheControl = useApolloCacheControl();
  const { i18n } = useLingui();

  const authenticationProps = useAuthInitialization();

  const { claims, getAccessToken: accessToken, userInfo } = authenticationProps;

  const isAnonymousUser = !!claims?.anon;

  const {
    applicationKey,
    autocompleteList,
    bluetickGraphqlUrl,
    brandName,
    facetsPreset,
    completemeGraphqlUrl,
    graphqlUrl,
    logo,
    printGraphqlUrl,
    searchGraphqlUrl,
    searchTabs,
    siteKey,
    urls,
    userPreferencesGraphqlUrl,
  } = useSiteConfig();

  const registerCacheInstanceContentRendering = useCallback(
    (cache: InMemoryCache) => {
      ApolloCacheControl.registerCache('content-rendering-provider', cache);
    },
    [ApolloCacheControl],
  );

  const registerCacheInstanceTitanCms = useCallback(
    (cache: InMemoryCache) => {
      ApolloCacheControl.registerCache('titan-cms-provider', cache);
    },
    [ApolloCacheControl],
  );

  const handlePrintErrors = useCallback(
    (type: 'mutation' | 'subscription', error?: ApolloError) => {
      if (!error) return;

      Sentry.captureException(error, {
        extra: {
          errorType: `ContentExportApi${type.charAt(0).toUpperCase() + type.slice(1)}Error`,
          errorMessage: error.message,
        },
      });
    },
    [],
  );

  const searchUrlResolver = useMemo(
    () =>
      constructSearchUrlResolver({
        searchUrl: urls.searchUrl,
        tabs: searchTabs,
        tabsFacet: facetsPreset.tabsFacet,
        magazineUrl: urls.magazineUrl,
      }),
    [urls.searchUrl, urls.magazineUrl, searchTabs, facetsPreset.tabsFacet],
  );
  return (
    <ErrorBoundary
      showDialog
      dialogOptions={sentryDialogOptions}
      fallback={<ErrorBoundaryPageFallback isFullScreen brandName={brandName} logo={logo} />}
    >
      <AuthenticationProvider {...authenticationProps}>
        <AnalyticsProvider>
          <SearchRootProvider
            accessToken={accessToken}
            applicationKey={applicationKey}
            bluetickGraphqlUrl={bluetickGraphqlUrl}
            completemeGraphqlUrl={completemeGraphqlUrl}
            contentBaseUri={urls.contentUrl}
            isAnonymousUser={isAnonymousUser}
            locale={i18n.locale}
            renderReadingListButton={renderReadingListButtonFunction}
            searchBaseUri={urls.searchUrl}
            searchGraphqlUrl={searchGraphqlUrl}
            searchUrlResolver={searchUrlResolver}
            siteId={siteKey}
            tabsFacet={facetsPreset.tabsFacet}
            userId={userInfo?.sub}
          >
            <ContentExportProvider
              accessToken={accessToken}
              applicationKey={applicationKey}
              contentGraphqlUrl={graphqlUrl}
              exportGraphqlUrl={printGraphqlUrl}
              siteName={brandName}
              urls={urls}
              onError={handlePrintErrors}
            >
              <ReadingListsProvider
                hasExternalI18nProvider
                accessToken={accessToken}
                applicationKey={applicationKey}
                baseUrl={urls.readingListsUrl}
                documentUrl={(itemReference) => getDocumentUrl({ itemReference, urls })}
                graphqlUrl={userPreferencesGraphqlUrl}
                siteId={siteKey}
                userId={userInfo?.sub}
              >
                <AlertsProvider>
                  <ContentRenderingProviderWithPlugins
                    apolloInitialState={ApolloCacheControl.getExtractedCache(
                      'content-rendering-provider',
                    )}
                    apolloRegisterCache={registerCacheInstanceContentRendering}
                    urls={contentRenderingUrls}
                  >
                    <TitanCmsProvider
                      apolloInitialState={ApolloCacheControl.getExtractedCache(
                        'titan-cms-provider',
                      )}
                      apolloRegisterCache={registerCacheInstanceTitanCms}
                    >
                      <SearchAutocompleteProvider list={autocompleteList}>
                        <NavigationProvider>
                          <InPageSearchProviderWithRouter>
                            <XposiProvider>{children}</XposiProvider>
                          </InPageSearchProviderWithRouter>
                        </NavigationProvider>
                      </SearchAutocompleteProvider>
                    </TitanCmsProvider>
                  </ContentRenderingProviderWithPlugins>
                </AlertsProvider>
              </ReadingListsProvider>
            </ContentExportProvider>
            <AuthComponents />
          </SearchRootProvider>
        </AnalyticsProvider>
      </AuthenticationProvider>
    </ErrorBoundary>
  );
}
interface AppProvidersProps {
  hasSimpleLayout?: boolean;
  router: AppProps['router'];
}
function AppProviders({ children, hasSimpleLayout }: React.PropsWithChildren<AppProvidersProps>) {
  if (hasSimpleLayout) {
    return <BaseLayoutSimple>{children}</BaseLayoutSimple>;
  }

  return (
    <AppProvidersWithAuth>
      <BaseLayout>{children}</BaseLayout>
    </AppProvidersWithAuth>
  );
}

interface AppHocProps {
  messages: Messages;
  isBot: boolean;
  cookies: {
    [key: string]: string;
  };
}

type AppPropsWithLayout = AppProps &
  AppHocProps & {
    Component: NextPageWithLayout;
  };
const App = ({
  messages,
  Component,
  pageProps: { session, ...pageProps },
  cookies,
  isBot,
  router,
}: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <SessionProvider
      refetchInterval={
        /** 59 minutes, in case jwt token is valid for 1 hour */
        60 * 59
      }
      refetchOnWindowFocus={false}
      session={session}
    >
      <TranslationsProvider messages={messages}>
        <DefaultSeo {...getDefaultSeo(config)} />
        <ThemeProvider
          defaultYOffset={26}
          designTokens={config.designTokens}
          useLinkResolver={useLinkResolver}
          useLinkWrapper={useLinkWrapper}
        >
          <Sentry.ErrorBoundary
            showDialog
            dialogOptions={sentryDialogOptions}
            fallback={
              <ErrorBoundaryPageFallback
                isFullScreen
                brandName={config.brandName}
                logo={config.logo}
              />
            }
          >
            <GlobalSiteStyling />
            <SiteConfigProvider
              config={{
                // FIXME: if types are fixed in psc for bluetick
                ...(config as any),
                isBot,
                urls: contentRenderingUrls,
              }}
              cookies={cookies}
            >
              <AppProviders hasSimpleLayout={pageProps.hasSimpleLayout} router={router}>
                {getLayout(<Component {...pageProps} />)}
              </AppProviders>
            </SiteConfigProvider>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </TranslationsProvider>
    </SessionProvider>
  );
};

const EnhancedApp: ReturnType<typeof withApolloServerSideRender> = withApolloServerSideRender(
  withServerContext<any, AppProps & AppHocProps>(
    withLingui(withCookies(withBot(App)), loadMessages),
  ),
);

export default EnhancedApp;
